import { useEffect, useState } from 'react';
import './Footer.css'
import axios from 'axios';

interface ContactInfo {
	address: string;
	city: string;
	email: string;
	id: number;
	instagram: string;
	phone: string;
	zip_code: string;
}

interface footerContent {
    contact_info: ContactInfo;
}

export const Footer = () => {

    const [footerContent, setFooterContent] = useState<footerContent | null>(null);

    useEffect(() => {
        const fetchFooterContent = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_STRAPI_URL + '/api/footer',
                    {
                        params: {
                            populate: 'contact_info',
                        },
                    }
                );

                const footerContent: footerContent = {
                    contact_info: response.data.data.attributes.contact_info
                };

                setFooterContent(footerContent);
            } catch (error) {
                console.log(error);
            }
        }

        fetchFooterContent();
    }, []);
    
    return (
        <div className="contact__info">
            <div>
                <a href="https://www.google.com/maps/place/Restaurang+Milles/@59.3326728,18.0753743,17z/data=!3m1!4b1!4m6!3m5!1s0x465f9d5a485d182b:0x1b0dae7db5bc9bc3!8m2!3d59.3326701!4d18.0779546!16s%2Fg%2F1pp2vkq40?entry=ttu" target="_BLANK" rel="noreferrer">
                    {footerContent?.contact_info.address || 'Strandvägen 1'}
                </a>
                <a href="https://www.google.com/maps/place/Restaurang+Milles/@59.3326728,18.0753743,17z/data=!3m1!4b1!4m6!3m5!1s0x465f9d5a485d182b:0x1b0dae7db5bc9bc3!8m2!3d59.3326701!4d18.0779546!16s%2Fg%2F1pp2vkq40?entry=ttu" target="_BLANK" rel="noreferrer">
                    {footerContent?.contact_info.zip_code || '114 51'}{' '}
                    {footerContent?.contact_info.city || 'Stockholm'}
                </a>
            </div>
            <div>
                <a href={`tel:${footerContent?.contact_info.phone || '+4686638002'}`}>
                    {footerContent?.contact_info.phone || '+46 8 663 80 02'}
                </a>
                <a
                    href={`mailto:${footerContent?.contact_info.email || 'info@mmilles.se'}`}
                    className="email"
                >
                    {footerContent?.contact_info.email || 'info@mmilles.se'}
                </a>
            </div>
            <div>
                <span>&nbsp;</span>
                <a
                    href={footerContent?.contact_info.instagram || 'https://www.instagram.com/michellevandermilles/'}
                    target="_BLANK"
                    rel="noreferrer"
                >
                    instagram
                </a>
            </div>
        </div>
    )
}