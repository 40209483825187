import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/Home.css';
import milles_logo from '../assets/img/milles_logo.svg';
import axios from 'axios';
import { Footer } from '../components/Footer/Footer';
import { BookButton } from '../components/BookButton/BookButton';
import { Helmet } from 'react-helmet';

interface OpeningHours {
	id: number;
	day_interval: string;
	hours: string;
}

interface HomePageContent {
	book_table_cta: string;
	menu_text: string;
	lunch_link_text: string;
	open_hours_heading: string;
	opening_hours: OpeningHours[];
	menu_url?: string;
	createdAt: Date;
	publishedAt: Date;
	updatedAt: Date;
}

export const Home = () => {
	const [homePageContent, setHomePageContent] = useState<HomePageContent | null>(null);

	useEffect(() => {
		const fetchHomePageContent = async () => {
			try {
				const response = await axios.get(
					process.env.REACT_APP_STRAPI_URL + '/api/home-page',
					{
						params: {
							populate: 'opening_hours, contact_info, menu',
						},
					}
				);

				const menu_url: string =
					process.env.REACT_APP_STRAPI_URL +
					response.data.data.attributes.menu.data.attributes.url;

				const homePageContent: HomePageContent = {
					book_table_cta: response.data.data.attributes.book_table_cta,
					menu_text: response.data.data.attributes.menu_text,
					open_hours_heading: response.data.data.attributes.open_hours_heading,
					opening_hours: response.data.data.attributes.opening_hours,
					menu_url: menu_url,
					lunch_link_text: response.data.data.attributes.lunch_link_text,
					createdAt: response.data.data.attributes.createdAt,
					publishedAt: response.data.data.attributes.publishedAt,
					updatedAt: response.data.data.attributes.updatedAt,
				};
				setHomePageContent(homePageContent);
			} catch (error) {
				console.log(error);
			}
		};

		fetchHomePageContent();
	}, []);

	return (
		<>
			<Helmet>
				<title>
					Restaurang Milles - Restaurang på Strandvägen | Milles
				</title>
				<meta
					name="description"
					content="Restaurang Milles har sedan 2013 erbjudit vällagad mat och god dryck i härlig atmosfär och stämning på Strandvägen i Stockholm. Välkommen!"
				/>
				<link rel="canonical" href="https://restaurangmilles.se/" />
			</Helmet>
			<main className="home__wrapper">
				<section className="container">
					<div className="home__content">
						<img src={milles_logo} alt="Milles logo" className="milles__logo" />
						<div className="open__hours__wrapper">
							<h2>{homePageContent?.open_hours_heading || 'Öppettider'}</h2>
							<div className="open__hours">
								{homePageContent?.opening_hours.map((hours, index) => (
									<div key={index}>
										<p>{hours.day_interval || 'Dag'}</p>
										<p>{hours.hours || '00:00 - 00:00'}</p>
									</div>
								)) || (
										<>
											<div>
												<p>Mån</p>
												<p>11:30 - 16:00</p>
											</div>
											<div>
												<p>Tis - Tor</p>
												<p>11:30 - 23:00</p>
											</div>
											<div>
												<p>Fre - Lör</p>
												<p>11:30 - 00:00</p>
											</div>
										</>
									)}
							</div>
						</div>
						<div className="menu">
							<a
								href={homePageContent?.menu_url || '/meny.pdf'}
								target="_blank"
								rel="noopener noreferrer"
							>
								{homePageContent?.menu_text || 'Meny'}
							</a>
							<Link to="/lunch">
								{homePageContent?.lunch_link_text || 'Lunch'}
							</Link>

						</div>
						<BookButton cta={homePageContent?.book_table_cta || 'Boka bord'} />
						<Footer />
					</div>
				</section>
			</main>
		</>
	);
};
