import React from 'react';
import './App.css';
import { Home } from './pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Lunch = React.lazy(() => import('./pages/Lunch/Lunch'));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lunch" element={<React.Suspense fallback={<div>Loading...</div>}><Lunch /></React.Suspense>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
