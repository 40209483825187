import pil from '../../assets/img/pil.svg';
import './BookButton.css';

export const BookButton = ({ cta }: { cta: string }) => {
    return (
        <div className="book__table">
            <a
                href="https://app.waiteraid.com/reservation/?hash=0d382f6f35b0bfa69542d81a04a50e24"
                target="_BLANK"
                rel="noreferrer"
                className="book__link"
            >
                {cta}
                <img src={pil} alt="Pil" className="pil" height="20" />
            </a>
        </div>
    )
}